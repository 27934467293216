<template>

	<div class="assign-card">
		<loading-indicator v-if="loading"></loading-indicator>
		<div v-if="!loading">
			<empty-assigned-route v-if="!loading && (!routes.length && edited)"> </empty-assigned-route>
			<list-assigned-routes
				:routes="routes"
				:eventId="eventId"
				@onDelete="handleDelete"
				@onEdited="handleEdited"
				v-if="!loading && (routes.length || !edited)"
				></list-assigned-routes>
		</div>
	</div>
</template>
<style scoped>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
</style>
<script>
import LoadingIndicator from './LoadingIndicator.vue'
import EmptyAssignedRoute from './EmptyAssignedRoute.vue'
import ListAssignedRoutes from './ListAssignedRoutes.vue'
export default {
  components: {
    LoadingIndicator,
    EmptyAssignedRoute,
    ListAssignedRoutes
  },
  props: {
    eventId: [Number, String],
    routes: {
      type: Array,
      default: () => []
    },
    loading : {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      edited: true
    }
  },
  emits: ['onDelete', 'onEdited'],
  methods: {
    handleDelete(data) {
      this.edited = false
      this.$emit('onDelete', data)
    },
    handleEdited(data) {
      this.edited = true
      const routes = data.map((route) => (
        {
          id: route.routeId,
          routeCode: route.routeCode,
          pickup: route.pickup,
          dropoff: route.dropoff,
        }
      ))
      this.$emit('onEdited', {routes})
    }
  }
}
</script>

<style lang="scss" scoped>
.assign-card {
  width: 100%;
}

@media (min-width: 1200px) {
  .assign-card {
    max-width: 428px;
  }
}
</style>
