<template>
	<div>
		<div class="card text-center">
			<div class="justify-content-center align-items-center p-4 m-2">
				<h4 class="">No routes assigned yet</h4>
			</div>
			<div class="p-2">
				<div class="mb-4">
					<div>
						<b-button variant="primary" @click="open">Assign route</b-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  methods: {
    open() {
      this.$bvModal.show('event-route-modal')
    },
  },
}
</script>
